import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveIcon from '@mui/icons-material/Remove';
import { colors, SvgIconProps } from '@mui/material';
import { appConst, RoleId } from 'src/constants/common';
import { routerPaths } from 'src/routes/path';
import { ValueOf } from 'src/utilities/typeUtils';

export const hasPermission = (path: string, roleId?: RoleId) => {
  let permission = false;

  switch (path) {
    case routerPaths.app.kanri.searchSupportRecipients:
    case routerPaths.app.kanri.supporters:
    case routerPaths.app.kanri.selectSupportRecipients:
    case routerPaths.app.kanri.settings.lineIntegration:
    case routerPaths.app.kanri.systemStopInformation:
    case routerPaths.app.kanri.error.systemError:
      permission = roleId === RoleId.JichitaiStaff || roleId === RoleId.SoshikiLeader;
      break;
    case routerPaths.app.kanri.safetyConfirmation:
    case routerPaths.app.kanri.settings.displayNonConsentingUsers:
    case routerPaths.app.kanri.settings.statusSetting:
    case routerPaths.app.kanri.settings.createAccount:
      permission = roleId === RoleId.JichitaiStaff;
      break;
    case routerPaths.app.shien.prs.summary:
    case routerPaths.app.shien.prs.detail:
    case routerPaths.app.shien.prs.action('visit'):
    case routerPaths.app.shien.prs.action('evacuate'):
    case routerPaths.app.shien.prs.safetySummary:
    case routerPaths.app.shien.prs.registerSafety:
    case routerPaths.app.shien.prs.registerShelterType:
    case routerPaths.app.shien.prs.selectShelter:
    case routerPaths.app.shien.prs.completeRegistration:
    case routerPaths.app.shien.prsList:
    case routerPaths.app.shien.spEnsuringSafety:
    case routerPaths.app.shien.possibility.question:
    case routerPaths.app.shien.possibility.answer.possible:
    case routerPaths.app.shien.possibility.answer.notPossible:
    case routerPaths.app.shien.profile:
    case routerPaths.app.shien.systemStopInformation:
    case routerPaths.app.shien.error.systemError:
      permission = roleId === RoleId.Supporter;
      break;
    default:
      permission = false;
      break;
  }

  return permission;
};

/**
 * 避難先に表示するテキストを生成する
 * @param statusId 未確認の場合空文字を返す
 * @param shelterTypeCode 1:自宅|2:避難所|3:その他|4:分からない
 * @param shelterName shelterTypeCode=2の場合に使用する避難所名
 * @param customShelterName shelterTypeCode=3の場合に使用するユーザ(支援者)が登録した避難先名
 * @param defaultName codeがnullの時,空文字/'避難先未登録'で表示が分かれるため
 * @returns 避難先に表示するテキスト 1:自宅 | 2:shelterName | 3:customShelterName 4:避難先不明 | 5:避難先未登録
 */

export const determineShelterName = (
  statusId: string,
  shelterTypeCode: string | null | undefined,
  shelterName: string,
  customShelterName: string,
  defaultName: {
    unregistered?: string; // 避難先未登録以外を表示したい場合
  } = {}
): string => {
  if (statusId === appConst.ID_PRS_STATUTS_UNCHECKED)
    return defaultName.unregistered ?? appConst.SHELTER_TYPE.SUMMARY_NAME.UNREGISTERED;

  switch (shelterTypeCode) {
    case appConst.SHELTER_TYPE.CODE.HOME: // 1
      return appConst.SHELTER_TYPE.SUMMARY_NAME.HOME; // 自宅
    case appConst.SHELTER_TYPE.CODE.SHELTER: // 2
      return shelterName;
    case appConst.SHELTER_TYPE.CODE.CUSTOM: // 3
      return customShelterName;
    case appConst.SHELTER_TYPE.CODE.UNKNOWN: // 4
      return appConst.SHELTER_TYPE.SUMMARY_NAME.UNKNOWN; //　避難先不明
    case appConst.SHELTER_TYPE.CODE.UNREGISTERED: // null
    default:
      return defaultName.unregistered ?? appConst.SHELTER_TYPE.SUMMARY_NAME.UNREGISTERED; // 避難先未登録
  }
};

type SupportAvailabilityView = {
  label: string;
  Icon: (iconProps: SvgIconProps) => React.ReactNode;
};

export const SUPPORT_AVAILABILITY_VIEWS = {
  '00000000-0000-0000-0000-000000000000': {
    label: '未回答',
    Icon: (iconProps: SvgIconProps) => <QuestionMarkIcon htmlColor={colors.grey[700]} {...iconProps} />,
  },
  '00000000-0000-0000-0000-000000000001': {
    label: '支援可',
    Icon: (iconProps: SvgIconProps) => <CircleOutlinedIcon color="success" {...iconProps} />,
  },
  '00000000-0000-0000-0000-000000000002': {
    label: '支援不可',
    Icon: (iconProps: SvgIconProps) => <ChangeHistoryIcon color="error" {...iconProps} />,
  },
} as const satisfies {
  [K in ValueOf<typeof appConst.SPCHECKID>]: SupportAvailabilityView;
};

export const getSupportAvailabilityView = (spCheckId: string): SupportAvailabilityView => {
  const isKnownId = (Object.values(appConst.SPCHECKID) as string[]).includes(spCheckId);
  if (isKnownId) {
    const id = spCheckId as ValueOf<typeof appConst.SPCHECKID>;
    return SUPPORT_AVAILABILITY_VIEWS[id];
  } else {
    return {
      label: '',
      Icon: (iconProps: SvgIconProps) => <RemoveIcon htmlColor={colors.grey[700]} {...iconProps} />,
    };
  }
};
