import { appConst, isKnownRoleId, RoleId } from 'src/constants/common';
import { routerPaths } from 'src/routes/path';

export type NavigateInfo = {
  /** システム停止案内画面など、無条件で遷移すべきページの場合`true` */
  enforce: boolean;
  path: string;
};

/**
 * ユーザーに応じた初期ページURLを返す
 * @param [spCheckId] 支援者ロールの場合に指定
 * @returns リダイレクト先が未実装画面の場合は`null`
 */
export const getInitialUrlByUser = (
  roleId: null | string,
  jianOngoing: boolean,
  spCheckId?: string
): null | NavigateInfo => {
  const normal = (path: string) => ({ enforce: false, path } as const satisfies NavigateInfo);
  const enforce = (path: string) => ({ enforce: true, path } as const satisfies NavigateInfo);

  // 要支援者
  if (roleId === null) {
    // NOTE: 要支援者向けのページはない
    return null;
  }

  // 未知のRoleId
  if (!isKnownRoleId(roleId)) {
    return enforce(routerPaths.app.shien.error.systemError);
  }

  switch (roleId) {
    case RoleId.Admin:
      // NOTE: 管理者向けのページはない
      return null;

    case RoleId.JichitaiStaff:
      return normal(routerPaths.app.kanri.searchSupportRecipients);
    case RoleId.SoshikiLeader:
      if (!jianOngoing) return enforce(routerPaths.app.kanri.systemStopInformation);

      return normal(routerPaths.app.kanri.searchSupportRecipients);

    case RoleId.Supporter:
      if (!jianOngoing) return enforce(routerPaths.app.shien.systemStopInformation);

      switch (spCheckId!) {
        case appConst.SPCHECKID.AVAILABLE:
          return normal(routerPaths.app.shien.prsList);
        case appConst.SPCHECKID.UNABLE:
        case appConst.SPCHECKID.NOT_ANSWERD:
        default:
          return normal(routerPaths.app.shien.spEnsuringSafety);
      }

    default:
      // 上記case句でRoleIdの全パターンが網羅されていない場合
      return enforce(routerPaths.error.systemError);
  }
};
