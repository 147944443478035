import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FullCirclarProgress } from 'src/components/common/atoms/FullCirclarProgress';
import { RoleId } from 'src/constants/common';
import { useJianInfo } from 'src/hooks/common/useJianInfo';
import { useNavigateSystemError } from 'src/hooks/common/useNavigateSystemError';
import { useSupporterDetails } from 'src/hooks/common/useSupporterDetails';
import { globalDataStore, jichitaiDataAtom, userDataAtom, userIdAtom } from 'src/jotai/atom';
import { getInitialUrlByUser } from 'src/routes/conditionalRedirect';
import { routerPaths } from 'src/routes/path';
import { loginWithRedirect } from 'src/utilities/auth/login';
import { getLoginAccount } from 'src/utilities/auth/loginAccount';
import { getUser } from 'src/utilities/restApi/common/users';
import { getJichitai } from 'src/utilities/restApi/shien/jichitais';
import { getPathWithParams } from 'src/utilities/urlUtil';

const jianCheckWhiteList = [
  routerPaths.index,
  routerPaths.azureSignin,
  routerPaths.callback.azure,
  routerPaths.error.systemError,
  routerPaths.error.notFound,
  routerPaths.app.kanri.error.systemError,
  routerPaths.app.kanri.systemStopInformation,
  routerPaths.app.shien.error.systemError,
  routerPaths.app.shien.systemStopInformation,
];

export const AuthAreaTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateSystemError = useNavigateSystemError();
  const { soshikis: ongoingSoshikis, jianOngoing } = useJianInfo();
  const [spId, setSpId] = useState<string>();
  const { details: supporterDetails, error: supporterDetailsError } = useSupporterDetails({ spId });

  const [userId] = useAtom(userIdAtom, { store: globalDataStore });
  const [userData, setUserData] = useAtom(userDataAtom, { store: globalDataStore });
  const [jichitaiData, setJichitaiData] = useAtom(jichitaiDataAtom, { store: globalDataStore });

  const isInitialMountRef = useRef(true);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (supporterDetailsError) {
      navigateSystemError();
    }
  }, [supporterDetailsError]);

  useEffect(() => {
    if (userId == null) {
      navigate(routerPaths.index);
      return;
    }

    if (userId) {
      getLoginAccount()
        .then((loginAccount) => {
          if (loginAccount === null) {
            // ログイン未実施
            navigate(routerPaths.index);
            return;
          }
          if (!loginAccount.isLoggedIn) {
            // sessionStorageに認可なし (新規タブなど)
            if (isInitialMountRef.current) {
              isInitialMountRef.current = false;
              loginWithRedirect(loginAccount.loginKind, { afterPagePath: getPathWithParams() }).catch(() =>
                navigateSystemError()
              );
            }
            return;
          }
          // ログイン済み
          getUser({ userid: userId })
            .then((response) => {
              if (response.data && response.data.user) {
                const user = response.data.user;
                setUserData({
                  userId: user.userid,
                  fName: user.fname,
                  sName: user.sname,
                  kfName: user.kfname,
                  ksName: user.ksname,
                  telNo: user.telno ?? undefined,
                  mailAddress: user.mailaddress ?? undefined,
                  isLinkedAadb2cuserid: user.is_linked_aadb2cuserid,
                  linkedAadb2cTypeCode: user.linked_aadb2c_type_code,
                  isLinkedLineUserId: user.is_linked_lineuserid,
                  roleId: (user.roleid as RoleId) ?? undefined,
                  roleName: user.rolename ?? undefined,
                  jichitaiId: user.jichitaiid,
                  jichitaiName: user.jichitainame,
                  soshikiids: user.soshikiids,
                });
              }
            })
            .catch(() => navigateSystemError());
        })
        .catch(() => navigateSystemError());
    }
  }, [userId]);

  useEffect(() => {
    if (jianCheckWhiteList.includes(location.pathname)) {
      setLoading(false);
    } else if (userData && ongoingSoshikis) {
      const ongoing = Boolean(
        jianOngoing() &&
          ongoingSoshikis.map((x) => x.soshikiid).some((ongoingId) => userData.soshikiids.includes(ongoingId))
      );

      if (ongoing && userData.roleId === RoleId.Supporter) {
        if (spId == null) {
          // 取得開始
          setSpId(userData.userId);
          return;
        } else if (!supporterDetails) {
          // 取得中
          return;
        }
      }

      const redirectInfo = getInitialUrlByUser(userData.roleId ?? null, ongoing, supporterDetails?.spcheckid);
      if (redirectInfo?.enforce) {
        navigate(redirectInfo.path, { replace: true });
        return;
      }

      setLoading(false);
    }

    if (userData && jichitaiData == null) {
      getJichitai({ jichitaiid: userData.jichitaiId })
        .then((response) => {
          if (response.data && response.data.jichitai) {
            const jichitai = response.data.jichitai;
            setJichitaiData({
              jichitaiId: jichitai.jichitaiid,
              evaJichitaiId: jichitai.evajichitaiid,
              name: jichitai.name,
              channelId: jichitai.channel_id,
            });
          }
        })
        .catch(() => navigateSystemError());
    }
  }, [userData, jichitaiData, location, ongoingSoshikis, supporterDetails]);

  return loading ? <FullCirclarProgress /> : <Outlet />;
};
